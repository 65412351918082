.ecommerce-page {
  background-color: #000;
  color: #fdf0e6;
  min-height: 100vh;

  .content-wrapper-ecommerce {
    background-color: #000;
    margin: 0 auto;
    padding: 2rem;

    @media (max-width: 768px) {
      padding: 1rem;
    }
  }

  .breadcrumb-ecommerce {
    font-size: 0.9rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  .breadcrumb-ecommerce a {
    color: #fdf0e6;
    text-decoration: none;
  }

  .breadcrumb-ecommerce span {
    color: #6200ea;
  }

  .section-content-ecommerce {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.3;
      }
    }

    p {
      font-size: 1.1rem;
      max-width: 100%;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  .ecommerce-features h2,
  .ecommerce-platforms h2,
  .cta-section h2 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 2rem;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .feature-item {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 1rem;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      cursor: pointer;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      color: #fff;
    }

    p {
      font-size: 0.9rem;
      color: rgba(255, 255, 255, 0.8);

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.active p {
      display: block;
    }
  }

  .ecommerce-platforms {
    color: white;
    text-align: center;

    .content-wrapper-ecommerce {
      background-color: #000;
    }

    .platforms-grid {
      display: flex;
      gap: 20px;
      justify-content: center;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .platform-item {
      background-color: #fff;
      color: #333;
      border-radius: 8px;
      padding: 20px;
      text-align: center;
      width: calc(33.33% - 20px);

      @media (max-width: 1024px) {
        width: calc(50% - 20px);
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .platform-logo {
      width: 100px;
      height: auto;
      margin-bottom: 10px;
    }

    .platform-button {
      display: inline-block;
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #6a1b9a; /* Purple color */
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #4a148c; /* Darker purple on hover */
      }
    }
  }

  .cta-section-wrapper {
    width: 100%;
    .content-wrapper-ecommerce {
      background-color: #6200ea;
    }
  }

  .cta-section {
    text-align: center;
    color: white;

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    .cta-button {
      background-color: white;
      color: #6200ea;
      border: none;
      padding: 12px 24px;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;

      @media (max-width: 768px) {
        font-size: 0.9rem;
        padding: 10px 20px;
      }

      &:hover {
        background-color: #6200ea;
      }
    }
  }

  .ecommerce-case-studies {
    background-color: #000;
    .content-wrapper-articles {
      background-color: #000;
      padding: 2rem;
    }

    .cases-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      margin-top: 2rem;
    }

    .case-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      aspect-ratio: 1;
      
      &:hover .case-overlay {
        opacity: 1;
      }
    }

    .case-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .case-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 2rem;
      opacity: 0;
      transition: opacity 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h3 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      p {
        margin-bottom: 2rem;
      }
    }

    .read-more-button {
      background: #6e2ff5;
      color: white;
      padding: 0.75rem 2rem;
      border-radius: 4px;
      text-decoration: none;
      transition: background 0.3s ease;

      &:hover {
        background: #5620c5;
      }
    }
  }
}
