.subscription-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    background-color: #000;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    position: relative;

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 1rem;
    }

    p {
      color: #ccc;
      margin-bottom: 1rem;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      select, input[type="email"] {
        padding: 0.5rem;
        border: 1px solid #333;
        background-color: #222;
        color: #fff;
      }

      button {
        padding: 0.5rem;
        background-color: #8B5CF6;
        color: white;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #45a049;
        }
      }
    }

    label {
      color: #ccc;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 1rem;
    }
  }
}
