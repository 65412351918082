.adobe-commerce-page {
    background-color: #000;
    color: #FDF0E6;
    min-height: 100vh;
    
    .adobe-commerce-case-studies {
      .content-wrapper-articles {
        padding: 2rem;
      }
    }
  
    .content-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  
    .breadcrumb {
      font-size: 0.9rem;
      margin-bottom: 2rem;
  
      a {
        color: #FDF0E6;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      span {
        color: #6200ea;
      }
    }
  
    .adobe-commerce-header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
  
      .adobe-commerce-logo {
        width: 150px;
        height: auto;
        margin-right: 1rem;
  
        @media (min-width: 768px) {
          width: 200px;
        }
      }
  
      h1 {
        font-size: 3rem;
        font-weight: 300;
      }
    }
  
    .intro-text {
      font-size: 1.2rem;
      max-width: 100%;
      margin-bottom: 3rem;
    }
  
    h2 {
      font-size: 2rem;
      color: #26E594;
      margin-bottom: 1.5rem;
    }
  
    .adobe-commerce-services, .adobe-commerce-features, .adobe-commerce-process, .faq {
      margin-bottom: 4rem;
    }
  
    .services-grid, .features-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  
    .service-item, .feature-item {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      transition: transform 0.3s ease, background-color 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
        background-color: rgba(255, 255, 255, 0.1);
      }
  
      .service-icon {
        font-size: 2rem;
        display: block;
        margin-bottom: 0.5rem;
      }
  
      .service-name, h3 {
        font-size: 1rem;
      }
    }
  
    .feature-item {
      cursor: pointer;
  
      &.active {
        background-color: rgba(255, 255, 255, 0.2);
      }
  
      p {
        margin-top: 1rem;
        font-size: 0.9rem;
      }
    }
  
    .process-steps {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
  
      .step {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        padding: 2rem 1rem 1rem;
        position: relative;
        margin-top: 1rem;
  
        .step-number {
          position: absolute;
          top: -15px;
          left: -15px;
          background-color: #FD5E53;
          color: #000;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }
  
        h3 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
  
        p {
          font-size: 0.9rem;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  
    .faq-item {
      margin-bottom: 1rem;
  
      h3 {
        font-size: 1.1rem;
        cursor: pointer;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
  
        &.active {
          background-color: #26E594;
          color: #000;
        }
      }
  
      p {
        font-size: 0.9rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0 0 4px 4px;
      }
    }
  
    .cta-section {
      background-color: #6200ea;
      width: 100%;
      padding: 60px 0;
      margin-top: 60px;
  
      .cta-content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
        text-align: center;
        p {
          color: #fff;
        }
  
        h2 {
          font-size: 1.5rem;
  
          @media (min-width: 768px) {
            font-size: 2rem;
          }
        }
      }
  
      .cta-button {
        background-color: #FD5E53;
        color: white;
        border: none;
        padding: 15px 30px;
        font-size: 1.1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 20px;
  
        &:hover {
          background-color: #ff7f75;
        }
      }
    }
  }
  
  .adobe-commerce-services {
    margin: 2rem 0;
  
    .services-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 2rem;
  
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      }
  
      .service-card {
        background: white;
        border-radius: 8px;
        padding: 1.5rem;
        text-align: center;
        transition: transform 0.3s ease;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        &:hover {
          transform: translateY(-5px);
        }
  
        .service-icon {
          font-size: 2.5rem;
          margin-bottom: 1rem;
          display: block;
        }
  
        h3 {
          margin: 0;
          font-size: 1.2rem;
          color: #333;
        }
  
        @media (min-width: 768px) {
          padding: 2rem;
        }
      }
    }
  }
  