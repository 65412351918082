.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background-color: #000000;  // Black background
  color: #ffffff;
  width: 100%;

  .header-top {
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
    .logo {
      img {
        height: 30px;
        width: auto;
      }
    }

    .nav-container {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .main-nav {
      ul {
        display: flex;
        cursor: pointer;
        list-style: none;
        gap: 2.5rem;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          cursor: pointer;
          a {
            text-decoration: none;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s ease;

            &:hover {
              color: rgba(112, 0, 255, 0.8);
            }

            &.nav-button {
              background: rgba(112, 0, 255, 0.8);
              border-radius: 50px;
              padding: 0.75rem 1.5rem;
              color: white;
              transition: background-color 0.3s ease;
              
              &:hover {
                background: rgba(112, 0, 255, 1);
              }
            }
          }
        }
      }
    }
  }

  .right-section {
    display: flex;
    align-items: center;

    .language {
      margin-right: 1rem;
      font-size: 0.85rem;
      color: #ffffff;
    }

    .search-btn, .menu-btn {
      background: none;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      margin-left: 0.5rem;
      color: #000;
      background-color: #000;
      transition: color 0.2s ease;

      &:hover {
        color: #FD5E53;
      }
    }
  }

  .full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #14142B 0%, #7000FF 100%);  // Dark purple gradient
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: radial-gradient(circle at top right, rgba(255, 255, 255, 0.1) 0%, transparent 60%);
      pointer-events: none;
    }

    &.open {
      opacity: 1;
      visibility: visible;
    }

    .menu-content {
      height: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 6rem 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      color: white;
      position: relative; // For absolute positioning of close button
      
      .close-menu-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 32px;
        height: 32px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;

        span {
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: white;
          left: 0;

          &:first-child {
            transform: rotate(45deg);
          }

          &:last-child {
            transform: rotate(-45deg);
          }
        }

        &:hover span {
          background-color: #FD5E53;
        }
      }

      .menu-left {
        h1 {
          font-size: 4rem;
          margin-bottom: 2rem;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 1rem;
          max-width: 600px;
        }
      }

      .menu-right {
        h2 {
          font-size: 2rem;
          margin-bottom: 2rem;
        }

        .pills-container {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          align-content: flex-start;
        }

        .menu-pill {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50px;
          padding: 0.75rem 1.5rem;
          color: white;
          text-decoration: none;
          transition: background-color 0.3s ease;
          font-size: 1rem;
          cursor: pointer;
          
          &:hover {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main-nav {
      display: none;
    }

    .full-screen-menu {
      .menu-content {
        grid-template-columns: 1fr;
        padding: 4rem 1rem;
        gap: 2rem;
        overflow-y: auto;
        height: 100vh;
        max-height: 100%;
        
        .menu-left {
          h1 {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
          }
          
          p {
            font-size: 1rem;
          }
        }

        .menu-right {
          h2 {
            font-size: 1.8rem;
            display: none;
          }

          .pills-container {
            gap: 0.75rem;
            padding-bottom: 2rem;
          }
        }

        .close-menu-btn {
          top: 1rem;
          right: 1rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      .main-nav {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Add this to your global styles or App.scss
body {
  background-color: #000000;
  color: #ffffff;
}

.menu-btn {
  background-color: #000000 !important;  // Force black background
  border: none;
  cursor: pointer;
  padding: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover {
    background-color: #000000 !important;  // Keep black background on hover
    color: rgba(112, 0, 255, 0.8);
  }
}

// Add these new styles for mobile menu
@media (max-width: 768px) {
  .full-screen-menu {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    
    &.open {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.full-screen-menu {
  @media (max-width: 768px) {
    .menu-content {
      padding: 3rem 1rem;
      
      .menu-left {
        h1 {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        
        p {
          font-size: 0.9rem;
          line-height: 1.4;
          margin-bottom: 0.75rem;
        }
      }

      .menu-right {
        h2 {
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
        }

        .pills-container {
          gap: 0.5rem;
        }

        .menu-pill {
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          min-width: calc(50% - 0.5rem);
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
}

// Add these styles for better mobile menu layout
@media (max-width: 768px) {
  .full-screen-menu {
    .menu-content {
      display: flex;
      flex-direction: column;
      
      .pills-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }
    }
  }
}

.full-screen-menu {
  @media (max-width: 768px) {
    .menu-content {
      .menu-left {
        display: none; // Hide the entire left section containing title and description
      }

      // Adjust the remaining content
      .menu-right {
        h2 {
          margin-top: 2rem; // Add some space at the top since we removed the left section
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .full-screen-menu {
    .menu-content {
      padding: 2rem 1rem;
      
      .menu-right {
        width: 100%;
        
        .pills-container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem; // Reduced gap between pills
          width: 100%;
        }

        .menu-pill {
          width: 100%;
          padding: 0.5rem; // Reduced padding
          font-size: 0.85rem; // Smaller font size
          text-align: center;
          box-sizing: border-box;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 35px; // Reduced height
          background: rgba(255, 255, 255, 0.1);
          border-radius: 25px; // Slightly reduced border radius
        }
      }

      // Hide the title
      h2 {
        display: none;
      }
    }
  }
}

// Base styles for menu-pill
.menu-right {
  .menu-pill {
    cursor: pointer !important;
    // ... existing styles ...
  }
}

// Mobile styles
@media (max-width: 768px) {
  .full-screen-menu {
    .menu-content {
      .menu-right {
        .menu-pill {
          cursor: pointer !important;
          // ... existing styles ...
        }
      }
    }
  }
}

// Add to Link element styles
a.menu-pill {
  cursor: pointer !important;
  // ... existing styles ...
}
