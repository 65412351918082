.about-page {
  background-color: #000000;
  color: #ffffff;
  padding: 2rem;
  min-height: 100vh;

  .services-content {
    max-width: 1200px;
    margin: 0 auto;
    .services-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
  }

  .what-we-do {
    margin-bottom: 4rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    .services-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      margin-bottom: 2rem;

      .service-item {
        text-align: center;

        .service-icon {
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        h3 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.4;
        }
      }
    }

    .view-services-btn {
      display: block;
      width: fit-content;
      margin: 0 auto;
      padding: 0.5rem 1rem;
      background-color: transparent;
      color: #ffffff;
      border: 1px solid #ffffff;
      text-decoration: none;
      font-weight: bold;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }

  // Add some spacing between sections
  > * {
    margin-bottom: 4rem;
  }

  // Style for potential paragraphs or other text content
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }

  // Style for potential lists
  ul, ol {
    max-width: 800px;
    margin: 1rem auto;
    padding-left: 2rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  // Style for potential images
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 2rem auto;
  }

  // Add some responsive adjustments
  @media (max-width: 768px) {
    padding: 1rem;

    h1 {
      font-size: 2.5rem;
    }

    .what-we-do {
      h2 {
        font-size: 1.8rem;
      }

      .services-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }
    }

    p {
      font-size: 1rem;
    }
  }
}

// You can add more specific styles for other components or sections as needed
