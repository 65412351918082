.connect-page {
  background-color: #6200ea;
  color: white;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-weight: bold;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
    }

    textarea {
      min-height: 120px;
      resize: vertical;
    }
  }

  .submit-button {
    background-color: black;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .connect-page {
    padding: 2rem 0;
    
    .form-container {
      padding: 0 15px;
    }
  }
}
