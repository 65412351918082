.services-grid-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  .service-content-wrap {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

// Add media query for tablet/mobile
@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.service-item {
  position: relative;
  width: 280px;
  margin: auto;
  height: 380px;
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
  perspective: 1000px;
  transform-style: preserve-3d;
  min-height: 380px;
  
  &:nth-child(1) { background-color: #6200ea; color: white; } // E-commerce
  &:nth-child(2) { background-color: #EF5350; color: white; } // App development
  &:nth-child(3) { background-color: #1A237E; color: white; } // Web development
  &:nth-child(4) { background-color: #66BB6A; color: white; } // Marketing
  
  &:hover {
    transform: translateY(-5px) rotateX(5deg) rotateY(5deg);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }

  .service-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: rgba(255, 255, 255, 0.2);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  h3 {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 1rem;
    opacity: 0.9;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .service-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  
}

// ... other existing styles ...
