.article-page {
  background-color: #fafafa;
  min-height: 100vh;
  padding: 2rem 0;
}

.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2.5rem;
  background-color: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    font-weight: bold;
    width: 100%;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  h2 {
    font-size: 1.3rem;
    color: #666;
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .article-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 2rem 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .article-content {
    line-height: 1.8;
    color: #3a4149;

    .article-paragraph {
      margin-bottom: 1.8rem;
      font-size: 1.1rem;
      color: #4a5568;
    }

    .article-list {
      margin: 1.5rem 0;
      padding-left: 2rem;

      li {
        margin-bottom: 1rem;
        line-height: 1.6;
      }
    }

    h1, h2, h3 {
      margin: 2.5rem 0 1.2rem;
      color: #2c3e50;
    }

    h3 {
      font-size: 1.4rem;
      font-weight: 600;
    }

    a {
      color: #3498db;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: all 0.2s ease;
      font-weight: 500;

      &:hover {
        color: #2980b9;
        border-bottom-color: #2980b9;
      }
    }
  }
}

// Responsive design
@media (max-width: 868px) {
  .article-container {
    margin: 0 1.5rem;
    padding: 2rem;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 480px) {
  .article-page {
    padding: 1rem 0;
  }

  .article-container {
    padding: 1rem;

    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.3rem;
    }

    .article-content {
      .article-paragraph {
        font-size: 1rem;
      }
    }
  }
}