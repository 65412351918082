.industries-page {
  background-color: #000;
  color: #FDF0E6;
  min-height: 100vh;
  padding: 4rem;
  padding: 2rem;

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  .breadcrumb {
    font-size: 0.9rem;
    margin-bottom: 2rem;

    a {
      color: #FDF0E6;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: #FD5E53;
    }
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 300;
    margin-bottom: 2rem;
    color: #26E594;
  }

  .industry-content {
    margin-bottom: 4rem;

    .description {
      font-size: 1.2rem;
      line-height: 1.6;
      max-width: 800px;
    }
  }

  .industries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-bottom: 4rem;

    .industry-item {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 1.5rem;
      text-align: center;
      transition: transform 0.3s ease, background-color 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        background-color: rgba(255, 255, 255, 0.1);
      }

      span {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .featured-industries {
    h2 {
      font-size: 2.5rem;
      font-weight: 300;
      margin-bottom: 1.5rem;
      color: #26E594;
    }

    .featured-description {
      font-size: 1.1rem;
      line-height: 1.6;
      max-width: 800px;
      margin-bottom: 3rem;
    }

    .featured-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 2rem;

      .featured-item {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }

        .featured-content {
          padding: 1.5rem;

          h3 {
            font-size: 1.3rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            color: #FD5E53;
          }

          p {
            font-size: 0.9rem;
            line-height: 1.5;
            margin-bottom: 1rem;
          }

          .learn-more {
            display: inline-block;
            color: #26E594;
            text-decoration: none;
            font-weight: 500;
            transition: color 0.3s ease;

            &:hover {
              color: #FD5E53;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .industries-page {
    .content {
      flex-direction: column;

      .description {
        max-width: 100%;
        margin-bottom: 2rem;
      }
    }

    .industries-list {
      flex-direction: column;

      .column {
        margin-bottom: 2rem;
      }
    }
  }

  .featured-industries {
    padding: 0 1rem;

    p {
      max-width: 100%;
    }

    .featured-grid {
      grid-template-columns: 1fr;
    }
  }
}
