.rubiks-loader {
  perspective: 1000px;
  margin: 0 auto;
  
  .rubiks-cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: rotateCube 10s infinite linear;
    
    &.solving {
      animation: solvingRotation 2s ease-in-out;
    }
    
    .face {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      
      &.front  { transform: translateZ(calc(var(--size) / 2)); }
      &.back   { transform: rotateY(180deg) translateZ(calc(var(--size) / 2)); }
      &.left   { transform: rotateY(-90deg) translateZ(calc(var(--size) / 2)); }
      &.right  { transform: rotateY(90deg) translateZ(calc(var(--size) / 2)); }
      &.top    { transform: rotateX(90deg) translateZ(calc(var(--size) / 2)); }
      &.bottom { transform: rotateX(-90deg) translateZ(calc(var(--size) / 2)); }
      
      .cube {
        width: calc(100% / 3);
        height: calc(100% / 3);
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        transition: all 0.5s ease;
        position: relative;
        overflow: hidden;
        
        .logo-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          
          .cube-logo {
            width: 70%;
            height: 70%;
            object-fit: contain;
            transition: opacity 0.5s ease;
          }
        }
        
        &.solving {
          .logo-wrapper {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

@keyframes rotateCube {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes solvingRotation {
  0% {
    transform: rotateX(0deg) rotateY(0deg) scale(1);
  }
  25% {
    transform: rotateX(90deg) rotateY(45deg) scale(1.1);
  }
  50% {
    transform: rotateX(180deg) rotateY(90deg) scale(1);
  }
  75% {
    transform: rotateX(270deg) rotateY(135deg) scale(1.1);
  }
  100% {
    transform: rotateX(360deg) rotateY(180deg) scale(1);
  }
}

// Add this new animation
@keyframes fadeInLogo {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// Add CSS variable for dynamic sizing
:root {
  --size: 200px;
}

// Add media queries for responsiveness
@media (max-width: 768px) {
  .rubiks-loader {
    --size: 150px;
  }
}

@media (max-width: 480px) {
  .rubiks-loader {
    --size: 100px;
  }
}
