.app-development-page {
  background-color: #000;
  color: #FDF0E6;
  min-height: 100vh;

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .breadcrumb {
    font-size: 0.9rem;
    margin-bottom: 2rem;

    a {
      color: #FDF0E6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: #FD5E53;
    }
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 300;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0.8rem;
    }
  }

  .intro-text {
    font-size: 1.2rem;
    max-width: 70%;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }
  }

  h2 {
    font-size: 2rem;
    color: #26E594;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  .technologies {
    margin-bottom: 4rem;

    .tech-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1.5rem;

      @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .tech-item {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      .tech-icon {
        font-size: 2rem;
        display: block;
        margin-bottom: 0.5rem;
      }

      .tech-name {
        font-size: 1rem;
      }
    }
  }

  .app-development-process {
    margin-bottom: 4rem;

    .process-steps {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 3rem;
        gap: 2.5rem;
      }

      .step {
        @media (max-width: 768px) {
          text-align: center;
        }
      }
    }

    .step {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 1.5rem;
      position: relative;

      .step-number {
        position: absolute;
        top: -15px;
        left: -15px;
        background-color: #FD5E53;
        color: #000;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

        @media (max-width: 768px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .faq {
    margin-bottom: 4rem;
    .faq-item {
      margin-bottom: 1rem;

      h3 {
        font-size: 1.1rem;
        cursor: pointer;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.active {
          background-color: #26E594;
          color: #000;
        }

        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 0.8rem;
        }
      }

      p {
        font-size: 0.9rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .cta-section-wrapper {
    background-color: #7000FF;
    padding: 60px 0;

    .content-wrapper-app {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .cta-section {
      text-align: center;

      h2 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #26E594;

        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }

      p {
        font-size: 1.1rem;
        margin-bottom: 30px;
        color: #FDF0E6;

        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 0 1rem;
        }
      }

      .cta-button {
        background-color: #FD5E53;
        color: #fff;
        padding: 12px 24px;
        font-size: 1.1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #26E594;
          color: #000000;
        }
      }
    }
  }

  .app-development-case-studies {
    margin: 4rem 0;
    
    h2 {
      font-size: 2rem;
      color: #26E594;
      margin-bottom: 2rem;
      text-align: center;
    }

    .cases-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      width: 100%;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0;
      }
    }
  }

  .articles-content-wrapper {
    padding: 0 20px;
  }
}
