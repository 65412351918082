.services-page {
  height: 100%;
  scroll-behavior: smooth;
  background-color: #000;
  color: #FDF0E6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-wrapper {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
}

.breadcrumb {
  font-size: 0.9rem;
  padding: 2rem;
  margin-bottom: 4rem;
}

.breadcrumb a {
  color: #FDF0E6;
  text-decoration: none;
}

.breadcrumb span {
  color: #6200ea;
}

.section-content {
  flex-grow: 1;
  display: flex;
  padding: 20px;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 3.5rem;
  max-width: 50%;
  font-weight: 300;
  line-height: 1.2;
}

.services-diagram {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.circle {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.circle:hover {
  transform: scale(1.1);
  z-index: 10;
}

.kreativitet {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.kompetens {
  bottom: 0;
  left: 25%;
  transform: translateX(-50%);
}

.konsulting {
  bottom: 0;
  right: 25%;
  transform: translateX(50%);
}

.kreativitet:hover {
  transform: translateX(-50%) scale(1.1);
}

.kompetens:hover {
  transform: translateX(-50%) scale(1.1);
}

.konsulting:hover {
  transform: translateX(50%) scale(1.1);
}

.service-content {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
}

.service-name {
  font-weight: bold;
  font-size: 1.6em;
  transition: all 0.3s ease;
}

.service-description {
  font-size: 1.1em;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.circle:hover .service-name {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.circle:hover .service-description {
  opacity: 1;
  max-height: 200px;
}

.detailed-services {
  padding: 4rem 4rem;
  text-align: left;
  background-color: #000;
  color: #fff;
}

.detailed-services h2 {
  font-size: 1.5rem;
  color: #00ff00;
  margin-bottom: 1rem;
}

.detailed-services > p {
  font-size: 1.1rem;
  max-width: 60%;
  margin-bottom: 4rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem 2rem;
}

.service-item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 2rem;
}

.service-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.service-item h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #fff;
}

.service-item p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.capabilities-btn {
  background: none;
  border: none;
  color: #00ff00;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.capabilities-btn:hover {
  text-decoration: underline;
}

.capabilities-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
}

.capabilities-list li {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .detailed-services > p {
    max-width: 100%;
  }
}

.capabilities-btn:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  h1 {
    max-width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.animated-circle {
  stroke-dasharray: 720;  /* New perimeter: 2 * (180 + 180) */
  stroke-dashoffset: 720;
  animation: fillCircle 2s ease-out forwards;
}

@keyframes fillCircle {
  to {
    stroke-dashoffset: 0;
  }
}

/* Add a slight delay to each circle for a staggered effect */
.animated-circle:nth-child(2) {
  animation-delay: 0.2s;
}

.animated-circle:nth-child(3) {
  animation-delay: 0.4s;
}

/* Add smooth scrolling to the whole page */
html {
  scroll-behavior: smooth;
}

.industries-section {
  width: 100%;
  padding: 4rem 2rem;
  background-color: #6200ea;
}

.industries-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.industries-section h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.industries-section p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.industries-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: opacity 0.3s ease;
}

.industries-link:hover {
  opacity: 0.8;
}

.industries-illustration {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 200px;
  height: 100px;
}

@media (max-width: 768px) {
  .industries-section {
    padding: 3rem 1.5rem;
    margin-bottom: 0;
  }

  .industries-content {
    max-width: 100%;
    padding: 0 1.5rem;
  }

  .industries-section h2 {
    font-size: 2.5rem;
  }

  .industries-illustration {
    position: static;
    margin-top: 2rem;
    align-self: center;
  }
}

.capabilities-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 1rem;
}

.capability-tag {
  background-color: rgba(128, 0, 128, 0.1);
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 0.9em;
  color: #e0b0ff;
  border: 1px solid #6200ea;
  transition: all 0.3s ease;
  text-decoration: none;
}

.capability-tag:hover {
  background-color: rgba(128, 0, 128, 0.2);
  color: #fff;
  border-color: #FD5E53;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(128, 0, 128, 0.3);
}

@media (max-width: 768px) {
  .services-diagram {
    width: 300px;
    height: 300px;
  }

  .circle {
    width: 150px;
    height: 150px;
  }

  .service-name {
    font-size: 1.2em;
  }

  .service-description {
    display: none;
  }

  .circle:hover {
    transform: none;
  }

  .circle:hover .service-name {
    opacity: 1;
    max-height: none;
    overflow: visible;
  }

  /* ... rest of the mobile CSS ... */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .services-title {
    font-size: 20px !important;
    padding: 0 20px;
  }

  .detailed-services {
    padding: 0;
  }

  .section-content h1 {
    font-size: 24px;
    line-height: 1.3;
  }

  .services-diagram {
    max-width: 300px; /* Smaller diagram for mobile */
  }

  .services-diagram svg {
    width: 300px;
    height: 300px;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .services-diagram {
    max-width: 250px;
  }

  .services-diagram svg {
    width: 250px;
    height: 250px;
  }

  .circle {
    width: 120px;
    height: 120px;
  }

  .service-name {
    font-size: 14px;
  }

  .service-description {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .services-grid {
    margin-bottom: 20px;
  }

  .services-diagram {
    width: 100%;
    max-width: 100%;
    transform: scale(0.8); /* Makes the diagram slightly smaller on mobile */
  }

  .circle {
    position: absolute;
    width: 120px;  /* Smaller circles for mobile */
    height: 120px;
  }

  /* Adjust circle positions */
  .circle.kreativitet {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .circle.kompetens {
    bottom: 0;
    left: 10px;
  }

  .circle.konsulting {
    bottom: 0;
    right: 10px;
  }
}

@media (max-width: 768px) {
  .services-diagram {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 400px;
    margin: 0 auto;
  }

  .circle {
    position: absolute;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    overflow: visible !important;
  }

  /* Position squares */
  .circle.kreativitet {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .circle.kompetens {
    bottom: 10px;
    left: 10px;
    transform: none;
  }

  .circle.konsulting {
    bottom: 10px;
    right: 10px;
    transform: none;
  }

  .service-content {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transform: translateY(-75px);
  }

  .kreativitet {
    .service-content {
      transform: translateY(-30px);
    }
  }

  .konsulting {
    .service-content {
      transform: translateY(-110px);
      .service-name {
        right: 25px;
        position: relative;
      }
    }
  }

  .kompetens {
    .service-content {
      transform: translateY(-110px);
      .service-name {
        left: 25px;
        position: relative;
      }
    }
  }

  .service-name {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    font-size: 1rem;
    color: #FDF0E6;
    text-align: center;
  }

  .service-description {
    display: none !important;
  }

  /* Disable hover effects */
  .circle:hover,
  .circle:hover .service-name {
    transform: none !important;
    opacity: 1 !important;
  }
}
