.landing-page {
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;

  .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8rem 2rem;
    position: relative;
    overflow: hidden;

    .content {
      max-width: 60%;
      z-index: 1;

      h1 {
        font-size: 3rem;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.6;
        margin-bottom: 2rem;
      }

      .cta-buttons {
        display: flex;
        gap: 1rem;

        .cta-button {
          padding: 0.75rem 1.5rem;
          background-color: #FD5E53;
          color: #fff;
          text-decoration: none;
          font-weight: bold;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #e54d42;
          }

          // Update media query for mobile devices
          @media (max-width: 768px) {
            font-size: 12px;
            padding: 8px 12px;
            white-space: nowrap;
          }
        }

        // Ensure buttons stay on one row on mobile
        @media (max-width: 768px) {
          flex-wrap: nowrap;
          justify-content: center;
        }
      }
    }

    .decorative-elements {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      width: 200px;
      height: 200px;

      .element {
        width: 40px;
        height: 40px;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.5s ease;
        transform: scale(0);
        transition: all 0.3s ease;
        cursor: pointer;

        &.active {
          opacity: 1;
          transform: scale(1);
        }

        &.blue { background-color: #0B0D63; }
        &.green { background-color: #FD5E53; }
        &.white { background-color: #ffffff; }
        &.outline { 
          border: 2px solid #ffffff; 
          background-color: #26E594;
        }

        &.clicked {
          transform: rotate(180deg);

          &.blue {
            background-color: #ff4081;
          }

          &.green {
            background-color: #ff9800;
          }

          &.outline {
            border-color: #e91e63;
          }

          &.white {
            background-color: #9c27b0;
          }
        }
      }
    }
  }

  .case-study-section {
    background-color: #000000;
    padding: 4rem 2rem;

    .case-study-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;

      .case-study-text {
        flex: 1;
        padding-right: 2rem;

        h2 {
          font-size: 1.5rem;
          color: #ffffff;
          margin-bottom: 1rem;
        }

        h3 {
          font-size: 2.5rem;
          color: #ffffff;
          margin-bottom: 2rem;
          font-weight: 300;
        }

        .learn-more-link {
          display: inline-block;
          color: #00ff00;
          text-decoration: none;
          font-weight: bold;
          border-bottom: 2px solid #00ff00;
          padding-bottom: 0.25rem;
          transition: color 0.3s ease, border-color 0.3s ease;

          &:hover {
            color: #ffffff;
            border-color: #ffffff;
          }
        }
      }

      .case-study-image {
        flex: 1;

        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }
      }
    }
  }

  .client-cases {
    padding: 4rem 2rem;

    h2 {
      text-align: center;
      margin-bottom: 3rem;
      font-size: 2.5rem;
      color: #fff;
    }

    .cases-container {
      display: flex;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;
    }

    .case-item {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      width: 300px;

      &:hover {
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      }

      .case-header {
        display: flex;
        align-items: center;
        padding: 1rem;
        color: #000;

        .iconize-logo {
          height: 24px;
          width: auto;
        }

        .logo-separator {
          margin: 0 0.5rem;
          color: #6c757d;
        }

        .client-name {
          font-size: 0.9rem;
          font-weight: bold;
        }
      }

      .case-content {
        flex-grow: 1;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;

        .case-image {
          width: 100%;
          height: 150px;
          object-fit: cover;
          margin-bottom: 1rem;
          border-radius: 4px;
        }

        h3 {
          margin-bottom: 0.75rem;
          font-size: 1.25rem;
          color: #333;
        }

        p {
          font-size: 0.9rem;
          color: #6c757d;
          margin-bottom: 1rem;
          line-height: 1.5;
        }

        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-top: auto;

          .tag {
            background-color: #f0f0f0;
            color: #333;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            font-size: 0.8rem;
          }
        }
      }

      .case-link {
        display: block;
        text-align: center;
        background-color: #6200ea;
        color: #ffffff;
        text-decoration: none;
        font-weight: 500;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        margin: 1rem;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #3700b3;
        }
      }
    }

    .view-all-link {
      display: block;
      text-align: center;
      margin-top: 3rem;
      font-weight: 500;
      color: #007bff;
      text-decoration: none;
      font-size: 1rem;
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3;
      }
    }
  }

  .testimonial {
    background-color: #000000;
    padding: 4rem 2rem;

    .testimonial-content {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;

      .client-info {
        flex: 1;

        img {
          max-width: 150px;
          margin-bottom: 1rem;
        }

        p {
          color: #ffffff;
          margin-bottom: 0.5rem;

          &:last-child {
            color: #cccccc;
          }
        }
      }

      blockquote {
        flex: 2;
        font-size: 1.5rem;
        color: #00ff00;
        margin: 0 2rem;
        line-height: 1.6;
      }

      .testimonial-link {
        align-self: flex-end;
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.3s ease;

        &:hover {
          color: #00ff00;
        }
      }
    }
  }

  .contact-us {
    background-color: #f0f0f0;
    padding: 8rem 2rem;

    .contact-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;

      .contact-text {
        flex: 1;

        h2 {
          font-size: 3rem;
          color: #000000;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.2rem;
          color: #333333;
          margin-bottom: 2rem;
        }

        .contact-button {
          display: inline-block;
          padding: 0.75rem 1.5rem;
          background-color: #000000;
          color: #ffffff;
          text-decoration: none;
          font-weight: bold;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #333333;
          }
        }
      }

      .decorative-plant {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        svg {
          max-width: 300px;
          height: auto;
        }
      }
    }
  }

  .services-section {
    padding: 4rem 2rem;
    background-color: #000;

    .services-content {
      max-width: 1200px;
      margin: 0 auto;

      h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        text-align: center;
      }

      > p {
        font-size: 1.1rem;
        margin-bottom: 2rem;
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }

      .cta-button {
        display: block;
        width: fit-content;
        margin: 2rem auto 0;
        padding: 0.75rem 1.5rem;
        background-color: #6200ea;
        color: white;
        text-decoration: none;
        font-weight: bold;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #2563EB;
        }
      }
    }
  }

  .testimonials-section {
    padding: 6rem 2rem;
    background-color: #000000;
    
    .testimonials-container {
      margin: 0 auto;
      max-width: 1200px;
      display: flex;
      gap: 4rem;

      .testimonials-header {
        flex: 1;
        max-width: 500px;
        
        h2 {
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
          color: #ffffff;
          font-weight: 300;
        }
        
        p {
          font-size: 1.1rem;
          line-height: 1.6;
          color: #ffffff;
          opacity: 0.7;
        }
      }

      .testimonials-grid {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        max-width: 600px;
        height: fit-content;
        
        &.visible {
          visibility: visible;
          animation: fadeIn 1s ease-out forwards;
        }

        .testimonial-card {
          aspect-ratio: 1;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 200px;
          opacity: 0;
          background-color: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
          transition: all 0.3s ease;
          
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            transform: translateY(-5px);
          }
          
          &.visible {
            animation: fadeIn 0.5s ease-out forwards;
          }

          &:nth-child(1) { animation-delay: 0.2s; }
          &:nth-child(2) { animation-delay: 0.4s; }
          &:nth-child(3) { animation-delay: 0.6s; }
          &:nth-child(4) { animation-delay: 0.8s; }

          h3 {
            font-size: 1.4rem;
            font-weight: 300;
            margin-bottom: 0.5rem;
            color: #ffffff;
          }

          .role {
            font-size: 0.9rem;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.6);
          }

          .quote {
            font-size: 0.95rem;
            line-height: 1.6;
            color: rgba(255, 255, 255, 0.8);
            font-style: italic;
          }

          &.green, &.coral, &.purple, &.navy {
            background-color: rgba(255, 255, 255, 0.03);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-page {
    .hero-section {
      flex-direction: column;
      padding: 2rem 1rem;

      .content {
        max-width: 100%;
        text-align: center;

        h1 {
          font-size: 2rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.9rem;
          margin-bottom: 1.5rem;
        }

        .cta-buttons {
          justify-content: center;
          align-items: center;

          .cta-button {
            padding: 0.6rem 1.2rem;
            font-size: 0.9rem;
            width: 50%;
          }
        }
      
      }

      @media (max-width: 480px) {
        .decorative-elements {
          display: none !important;
        }
      }

      .decorative-elements {
        position: static;
        transform: none;
        width: 150px;
        height: 150px;
        margin: 1.5rem auto 0;

        .element {
          width: 30px;
          height: 30px;
        }
      }
    }

    .case-study-section {
      padding: 2rem 1rem;

      .case-study-content {
        .case-study-text {
          padding-right: 0;
          margin-bottom: 1.5rem;

          h2 {
            font-size: 1.2rem;
          }

          h3 {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    .client-cases {
      padding: 2rem 1rem;

      .cases-container {
        flex-wrap: wrap;
      }

      .case-item {
        width: 100%;
      }
    }

    .testimonial {
      padding: 2rem 1rem;

      .testimonial-content {
        flex-direction: column;

        .client-info {
          margin-bottom: 1rem;

          img {
            max-width: 100px;
          }

          p {
            font-size: 0.8rem;
          }
        }

        blockquote {
          font-size: 1.2rem;
          margin: 1rem 0;
        }
      }
    }

    .contact-us {
      padding: 2rem 1rem;

      .contact-content {
        .contact-text {
          h2 {
            font-size: 2rem;
          }

          p {
            font-size: 1rem;
          }

          .contact-button {
            padding: 0.6rem 1.2rem;
            font-size: 0.9rem;
          }
        }

        .decorative-plant {
          svg {
            max-width: 200px;
          }
        }
      }
    }

    .services-section {
      padding: 2rem 1rem;

      .services-content {
        h2 {
          font-size: 2rem;
        }

        > p {
          font-size: 0.9rem;
        }

       

        .cta-button {
          padding: 0.6rem 1.2rem;
          font-size: 0.9rem;
        }
      }
    }

    .testimonials-section {
      padding: 3rem 1rem;

      .testimonials-container {
        .testimonials-grid {
          grid-template-columns: 1fr;
          
          .testimonial-card {
            padding: 1rem;

            h3 {
              font-size: 1.5rem;
            }

            .role {
              font-size: 1rem;
            }

            .quote {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .hero-section {
      .cta-buttons {
        flex-direction: column;

        .cta-button {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonials-section {
  .testimonials-container {
    // Update container for mobile
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
      
      .testimonials-header {
        max-width: 100%;
        text-align: center;
        
        h2 {
          font-size: 2rem;
        }
        
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

.case-study-section {
  .case-study-content {
    // Add mobile styles
    @media (max-width: 768px) {
      flex-direction: column;
      
      .case-study-text {
        text-align: center;
        margin-bottom: 2rem;
        padding-right: 0;
        
        h2 {
          font-size: 1.2rem;
        }
        
        h3 {
          font-size: 1.8rem;
        }
      }
      
      .case-study-image {
        width: 100%;
        
        img {
          max-width: 100%;
        }
      }
    }
  }
}

.services-section {
  .services-content {
    // Add mobile styles
    @media (max-width: 768px) {
      padding: 0 1rem;
      
      h2 {
        font-size: 2rem;
      }
      
      > p {
        font-size: 0.9rem;
        padding: 0 1rem;
      }
      
      .cta-button {
        font-size: 0.9rem;
        padding: 0.6rem 1.2rem;
        text-align: center;
      }
    }
  }
}
