.article-card {
    position: relative;
    background-color: black;
    color: white;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  
      .article-card__image {
        transform: scale(1.05);
      }
    }
  
    &__image-container {
      position: relative;
      width: 100%;
      height: 200px;
      overflow: hidden;
    }
  
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  
    &__content {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      gap: 0.75rem;
    }
  
    &__title {
      font-size: 1.1rem;
      font-weight: 600;
      color: #000;
      margin-bottom: 1rem;
    }
  
    &__description {
      font-size: 0.9rem;
      color: #000;
      line-height: 1.5;
      margin-bottom: 1.5rem;
    }
  
    &__button {
      display: inline-block;
      color: white;
      text-decoration: none;
      background-color: #6200ea;
      padding: 0.5rem 0;
      border-bottom: 2px solid white;
      padding: 0.5rem 1rem;
      text-align: center;
      transition: opacity 0.3s ease;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 16px;
    }
  
    &__tag {
      display: inline-flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      transition: all 0.2s ease;
      
      // Different colors for different tags
      &:nth-child(1) { background: rgba(66, 153, 225, 0.1); color: #3182ce; }
      &:nth-child(2) { background: rgba(72, 187, 120, 0.1); color: #38a169; }
      &:nth-child(3) { background: rgba(237, 137, 54, 0.1); color: #dd6b20; }
      &:nth-child(4) { background: rgba(159, 122, 234, 0.1); color: #805ad5; }
      
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .article-card {
      &__image-container {
        height: 180px;
      }
  
      &__content {
        padding: 1.25rem;
        height: calc(100% - 180px);
      }
  
      &__title {
        font-size: 1.1rem;
      }
  
      &__description {
        font-size: 0.9rem;
        margin-bottom: 1.25rem;
      }
  
      &__button {
        padding: 0.6rem 1.5rem;
      }
    }
  }