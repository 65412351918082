.footer {
  background-color: #FD5E53;
  color: #ffffff;
  padding: 4rem 2rem;
  width: 100%;
  position: relative;
  bottom: 0;

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .social-media {
    margin-bottom: 3rem;

    .social-icons {
      display: flex;
      gap: 2rem;

      a {
        color: #ffffff;
        font-size: 1.5rem;
        transition: color 0.3s ease;

        &:hover {
          color: #FD5E53;
        }
      }
    }
  }

  .connect {
    margin-bottom: 3rem;

    .connect-columns {
      display: flex;
      gap: 2rem;

      .newsletter, .contact-us {
        flex: 1;

        h4 {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.9rem;
          margin-bottom: 1.5rem;
          line-height: 1.5;
        }

        button {
          background-color: transparent;
          border: 1px solid #FD5E53;
          color: #fff;
          background-color: #8B5CF6;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background-color: #FD5E53;
            color: #000000;
          }
        }

        // Mobile styles
        @media (max-width: 768px) {
          text-align: center;
          
          h4 {
            text-align: center;
          }

          p {
            text-align: center;
          }

          .newsletter-form {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .copyright {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 1rem 0;
    margin-bottom: 2rem;

    p {
      font-size: 0.9rem;
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;

    .link-column {
      display: flex;
      flex-direction: column;

      a {
        color: #ffffff;
        text-decoration: none;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        transition: color 0.3s ease;

        &:hover {
          color: #FD5E53;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 0;
    .social-media {
      padding: 0 2rem;
    }
    .connect {
      padding: 2rem;
      .connect-columns {
        flex-direction: column;
      }
    }

    .footer-links {
      flex-direction: column;
      padding: 2rem;
      gap: 2rem;
    }
  }
}

.newsletter {
  .newsletter-form {
    display: flex;
    gap: 8px;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
      input[type="email"] {
        width: unset !important;
      }
    }

    input[type="email"] {
      flex: 1;
      padding: 12px 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.05);
      color: #fff;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 100%;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &:focus {
        outline: none;
        border-color: rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.1);
      }
    }

    button[type="submit"] {
      padding: 12px 24px;
      background: #8B5CF6;
      border: 1px solid #7C3AED;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      white-space: nowrap;

      &:hover {
        background: #7C3AED;
        border-color: #6D28D9;
      }

      &:active {
        transform: translateY(1px);
        background: #6D28D9;
      }
    }
  }

  @media (max-width: 768px) {
    .newsletter-form {
      .input-wrapper {
        max-width: 100%;
      }
    }
  }
}

// Update toast styling to match the site's aesthetic
:root {
  --toastify-color-success: #4CAF50;
  --toastify-color-error: #f44336;
  --toastify-font-family: inherit;
  --toastify-text-color-light: #333;
}

.Toastify__toast {
  border-radius: 4px;
  font-size: 0.9rem;
}

.Toastify__toast-body {
  font-family: inherit;
}
