.marketing-page {
  background-color: #000;
  color: #FDF0E6;
  min-height: 100vh;

  .content-wrapper-articles {
    padding: 2rem;
  }

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .breadcrumb {
    font-size: 0.9rem;
    margin-bottom: 2rem;

    a {
      color: #FDF0E6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: #FD5E53;
    }
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 300;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0.8rem;
    }
  }

  .intro-text {
    font-size: 1.2rem;
    max-width: 70%;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }
  }

  h2 {
    font-size: 2rem;
    color: #26E594;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  .services {
    margin-bottom: 4rem;

    .service-marketing-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      background: transparent;
      padding: 1rem;
      
      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
      
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }
      
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    .service-item {
      background: rgba(38, 229, 148, 0.03);
      border-radius: 12px;
      padding: 2rem;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      transition: all 0.3s ease;
      border: 1px solid rgba(38, 229, 148, 0.1);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(38, 229, 148, 0.5),
          transparent
        );
        transform: translateX(-100%);
        transition: transform 0.6s ease;
      }

      &:hover {
        background: rgba(38, 229, 148, 0.05);
        transform: translateY(-4px);
        border-color: rgba(38, 229, 148, 0.2);

        &::before {
          transform: translateX(100%);
        }

        .service-icon {
          transform: scale(1.1);
          background: rgba(38, 229, 148, 0.15);
        }
      }

      .service-icon {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(38, 229, 148, 0.1);
        border-radius: 12px;
        transition: all 0.3s ease;
        margin-bottom: 0.5rem;

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          filter: brightness(0.95);
        }
      }

      .service-name {
        font-size: 1.1rem;
        font-weight: 500;
        color: #26E594;
        text-align: center;
        margin: 0;
      }

      .service-description {
        font-size: 0.9rem;
        color: #888;
        text-align: center;
        margin-top: 0.5rem;
        line-height: 1.4;
      }
    }
  }

  .seo-services {
    margin-bottom: 4rem;

    .seo-list {
      list-style-type: none;
      padding: 0;

      li {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        padding: 1rem;
        margin-bottom: 1rem;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }

      @media (max-width: 768px) {
        padding: 0 1rem;
        
        li {
          text-align: center;
          font-size: 0.9rem;
        }
      }
    }
  }

  .marketing-process {
    margin-bottom: 4rem;

    .process-steps {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 3rem;
        gap: 2.5rem;
      }
    }

    .step {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 1.5rem;
      position: relative;

      .step-number {
        position: absolute;
        top: -15px;
        left: -15px;
        background-color: #FD5E53;
        color: #000;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

        @media (max-width: 768px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }

      p {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .faq {
    margin-bottom: 4rem;
    
    .faq-item {
      margin-bottom: 1rem;

      h3 {
        font-size: 1.1rem;
        cursor: pointer;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.active {
          background-color: #26E594;
          color: #000;
        }

        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 0.8rem;
        }
      }

      p {
        font-size: 0.9rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .cta-section {
    background-color: #7f54b3;
    width: 100%;
    padding: 60px 0;
    margin-top: 60px;

    .cta-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
      text-align: center;
      p {
        color: #fff;
      }

      h2 {
        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }

      p {
        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 0 1rem;
        }
      }

      .cta-button {
        background-color: #FD5E53; // WooCommerce purple
        color: white;
        border: none;
        padding: 15px 30px;
        font-size: 1.1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 20px;

        &:hover {
          background-color: #674399; // Darker shade for hover
        }

        @media (max-width: 768px) {
          font-size: 1rem;
          padding: 12px 20px;
        }
      }
    }
  }

  .marketing-case-studies {
    margin: 4rem 0;
    
    .content-wrapper-articles {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .cases-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      width: 100%;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0;
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .services .service-marketing-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }

  .service-item {
    padding: 1.25rem 1rem;
    
    .service-icon {
      font-size: 2rem;
    }

    .service-name {
      font-size: 0.9rem;
    }
  }
}
