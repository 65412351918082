.interactive-form-section {
  background-color: #6200ea;
  color: white;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-weight: bold;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
    }

    textarea {
      min-height: 120px;
      resize: vertical;
    }
  }

  .services-checkboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;

    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: normal;
    }
  }

  .submit-button {
    background-color: black;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    align-self: flex-start;

    &:hover {
      background-color: #333;
    }
  }
}

@media (max-width: 768px) {
  .interactive-form-section {
    padding: 2rem 1rem;

    .form-container {
      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }

      form {
        .form-group {
          .checkbox-group {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
